import algo from "../../img/algo.png";
import jobify from "../../img/jobify.png";
import lbwave from "../../img/lbwave.png";
import mixed from '../../img/mixed.png';
import soundbath from "../../img/soundbath.png";


import PortfolioCategory from "../portfolio-categories/PortfolioCategory";

import "./portfolio-grid.styles..scss";
const PortfolioGrid = () => {
  const projects = [
    {
      id: 1,
      title: "Soraya Sound Healing",
      description: "Sound Healing website template for Client",
      image: soundbath,
      webLink: "https://sorayasoundhealing.netlify.app/",
      sourceLink: "https://github.com/jgodi96/Soraya-Sound-Healing",
      
    },
    {
      id: 2,
      title: "Mixed By AJ",
      description: "Music Business Website for Client",
      image: mixed,
      webLink: "https://music-site-psi.vercel.app/",
      sourceLink: "https://github.com/jgodi96/music-site/tree/main/aj-music-site",
    },

    {
      id: 3,
      title: "LB Wave Clothing",
      description: "Long Beach Clothing Line Template",
      image: lbwave,
      webLink: "https://lbwaveclothing.netlify.app",
      sourceLink: "https://github.com/jgodi96/lbwave-clothing",
    },
    
    {
      id: 4,
      title: "Dijkstra's algorithm",
      description: "Visualize Dijkstra's Algorithm",
      image: algo,
      webLink: "http://jgodi96.pythonanywhere.com",
      sourceLink: "https://github.com/jgodi96/PathFinderAlgorithmVisualizer",
    },
    
  ];

  return (
    <div className="directory-container">
      {projects.map((project) => (
        <PortfolioCategory key={project.id} project={project} />
      ))}
    </div>
  );
};
export default PortfolioGrid;
