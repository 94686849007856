import './not-found.styles.scss'
const NotFound = () => {
    return (
        <div class="not-found-container">
            <h1 className="number-error">404</h1>
            <h2 className="error-type">Not Found</h2>
           
        </div>
    )
}
export default NotFound;