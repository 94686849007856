import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {AppProvider} from './context/appContext'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <AppProvider>
    <App/>
    </AppProvider>
    </BrowserRouter>
    
  </React.StrictMode>,
  document.getElementById('root')
);
