import React from 'react'
import Title from '../../components/title/Title'
import Contact from '../../components/contact/contact'
import './contact-page.styles.scss'
function ContactPage() {
    return (
        <div className='contact-page'>


            <Title title={'Contact'} span = {'Contact'}/>
            <Contact/>
            </div>
    )
}

export default ContactPage
