import "./contact.styles.scss";
import InfoSection from "./info-section";
import Form from './form'
const Contact = () => {
  return (
    // <div className="contact-container">
      <div className="contact-card-container">


        <Form/>
        <InfoSection/>
      </div>
    //</div>
  );
};
export default Contact;
