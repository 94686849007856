import React, { useReducer, useContext } from "react";
import reducer from "./reducer";
import {
  START_REQUEST,
  END_REQUEST,
  MOBILE_NAV_SHOW,
  MOBILE_NAV_HIDE,
  START_SUCCESS_MESSAGE,
  END_SUCCESS_MESSAGE,
} from "./actions";

const initialState = {
  isSubmitting: false,
  showMobileNav: false,
  showMessage: false,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const startRequest = () => {
    dispatch({ type: START_REQUEST });
  };

  const endRequest = () => {
    dispatch({ type: END_REQUEST });
  };
  const showNav = () => {
    dispatch({ type: MOBILE_NAV_SHOW });
  };
  const hideNav = () => {
    dispatch({ type: MOBILE_NAV_HIDE });
  };
  const showSuccessMessage = () => {
    dispatch({ type: START_SUCCESS_MESSAGE });
  };
  const hideSuccessMessage = () => {
    dispatch({ type: END_SUCCESS_MESSAGE });
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        startRequest,
        endRequest,
        showNav,
        hideNav,
        showSuccessMessage,
        hideSuccessMessage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
