import "./App.styles.scss";
import NavBar from "./components/nav/NavBar";
import HomePage from "./Pages/home/HomePage";
import { Switch, Route } from "react-router-dom";
import AboutPage from "./Pages/about/AboutPage";
import PortfolioPage from "./Pages/portfolio/PortfolioPage";
import ContactPage from "./Pages/contact/ContactPage";
import MobileNav from "./components/mobile-nav/mobile-nav";
import NotFound from './Pages/not-found/not-found'


function App() {

  return (
    <>
      
   <MobileNav/>

      <div className="App">
        <div className="sidebar">
          <NavBar />
        </div>

        {/* <div className="main-content"> */}
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/about" exact>
              <AboutPage />
            </Route>
            <Route path="/portfolio" exact>
              <PortfolioPage />
            </Route>
            <Route path="/contact" exact>

              <ContactPage />
            </Route>

            <Route path="*" exact>
              <NotFound/>
            </Route>
          </Switch>
        </div>
      {/* </div> */}
    </>
  );
}

export default App;
