import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { useState } from "react";
import "./mobile-nav.styles.scss";
import NavBar from "../nav/NavBar";
import { useAppContext } from "../../context/appContext";

const MobileNav = () => {
  const { showNav, hideNav, showMobileNav } = useAppContext();

  const [initialNav, setInitialNav] = useState(false);
  const closeHandler = () => {
    hideNav();
    setInitialNav(true);
  };
  const openHandler = () => {
    showNav();
    setInitialNav(true);
  };
  return (
    <div className="topbar">
      {showMobileNav ? (
        <IoCloseSharp className="button-close" onClick={closeHandler} />
      ) : (
        <GiHamburgerMenu className="button-hamburger" onClick={openHandler} />
      )}
      <div
        className={`mobile-nav-container ${!showMobileNav && "close-nav"} ${
          !initialNav && "close"
        }`}
      >
        <NavBar />
      </div>
    </div>
  );
};

export default MobileNav;
