import React from "react";
import Title from "../../components/title/Title";
import AboutSection from "../../components/about-section/AboutSection";
import "./about-page.styles.scss";
function AboutPage() {
  return (
    <div className="about-page">
      <Title title={"About"} span={"About"} />
      <AboutSection />
    </div>
  );
}

export default AboutPage;
