import React from "react";
import PortfolioGrid from "../../components/porfolio-grid/PortfolioGrid";
import Title from "../../components/title/Title";
import "./portfolio-page.styles.scss";

function PortfolioPage() {
  return (
    <>
      
      <div className="portfolio-page">
      <Title title={"Portfolio"} span={"Portfolio"} />
        <PortfolioGrid />
      </div>
    </>
  );
}

export default PortfolioPage;
