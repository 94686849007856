import emailjs from '@emailjs/browser';

export const sendMessageForm = (currentForm) => {
    emailjs.sendForm('gmail', 'email_template', currentForm, '8w12B_UI6Ei6_Cur-').then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}

