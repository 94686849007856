import "./about.styles.scss";
import { Link } from "react-router-dom";
const AboutSection = () => {
  return (
    <div className="about-section">
      <h1 className="hello-h"> Hello! </h1>
      <div className="about-info">
        {" "}
        I am a passionate software engineer with four years of experience, currently working as an application developer for Los Angeles County Department of Health Services. I’ve had the privilege of working with industry leaders like Microsoft and Tractor Supply, honing my skills as a front-end engineer. I also love creating websites that not only look great but also provide a seamless user experience. Currently residing in Long Beach, California, I’m excited about leveraging technology to help others bring their ideas to life!      </div>
      <div className="tech-list">
        <h3>Technologies</h3>
        <ul>
          <li>
            {" "}
            <span>-</span> React Js
          </li>
          <li>
            <span>-</span> React Native
          </li>
          <li>
            <span>-</span> Node Js
          </li>
          <li>
            <span>-</span> Next Js
          </li>
          <li>
            <span>-</span> Angular
          </li>
          <li>
            <span>-</span> Python
          </li>
        </ul>
      </div>
      <p className="resume-request">Resume Available Upon Request</p>
      <Link className="button-c" to="/contact">
        <button className="btn">Contact</button>
      </Link>
    </div>
  );
};
export default AboutSection;
