import React from "react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./home.styles.scss";
//

function HomePage() {
  return (
    <div className="HomePage">
      <div className="hero">
        <h1 className="hero-text">
          <div className="jump-text">
            <span>H</span>
            <span>i</span>
            <span>,</span>
            &nbsp;
            <span>I</span>
            &nbsp;
            <span>A</span>
            <span>m</span>
          </div>
          <span className="slide-name"> Joshua Godinez.</span>
        </h1>
        <p className="h-sub-text">Software Engineer</p>
        {/* <div className="icons">
          <a className="icon-holder" href="https://github.com/jgodi96">
            <FontAwesomeIcon icon={faGithub} className="icon gh" />
          </a>
          <a className="icon-holder" href="https://www.linkedin.com/in/jgodi96">
            <FontAwesomeIcon icon={faLinkedin} className="icon lin" />
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default HomePage;
