import { BsArrowUpRightSquare } from "react-icons/bs";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./portfolio-container-styles.scss";
const PortfolioCategory = ({ project }) => {
  return (
    <div className="category-container">
      <img className="background-image" src={project.image} alt="" />

      <div className="category-body-container">
        <h2>{project.title}</h2>
        <p>{project.description}</p>
        <div className="visit-container">
          <a className="visit-link" href={project.webLink}>
            Visit Site <BsArrowUpRightSquare className="arrow-right" />
          </a>

          <a className="visit-link" href={project.sourceLink}>
            Visit Source
            <FontAwesomeIcon icon={faGithub} className="github" />
          </a>
        </div>
        <div className="visit-container">
          
        </div>
      </div>
    </div>
  );
};
export default PortfolioCategory;
