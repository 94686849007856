import "./form.styles.scss";
import { useRef } from "react";
import useForm from "../../hooks/useForm";
import validate from "../../errors/validateInfo";
import { useAppContext } from "../../context/appContext";
import Spinner from "../spinner/spinner.component";
import Input from "react-phone-number-input/input";
import { useState } from "react";

const Form = () => {
  const form = useRef();
  const { isSubmitting, hideSuccessMessage, showMessage } = useAppContext();
  const { handleChange, values, handleSubmit, errors } = useForm(
    validate,
    form.current
  );

  const { name, email, subject, message } = values;

  const [value, setValue] = useState("");

  const handleForm = (e) => {
    handleSubmit(e, form.current);

    setTimeout(() => {
      setValue("");
    }, 1000);

    setTimeout(() => {
      hideSuccessMessage();
    }, 5000);
  };

  return (
    <form ref={form} onSubmit={handleForm} className="form-section">
      <h1>Send a Message</h1>

      <div className="input-row">
        <div className="first-input">
          <h3>Name*</h3>
          <div className="input-container">
            <input
              type="text"
              placeholder="name*"
              className="inputs"
              name="name"
              value={name}
              onChange={handleChange}
            ></input>
            {errors.name && <p className="error-paragraph">{errors.name}</p>}
          </div>
        </div>

        <div className="second-input">
          <h3>Phone</h3>
          <div className="input-container">
            <Input
              country="US"
              placeholder="phone (optional)"
              className="inputs"
              name="phone"
              value={value}
              onChange={setValue}
              maxLength={14}
            ></Input>
          </div>
        </div>
      </div>

      <div className="input-row">
        <div className="first-input">
          <h3>Email*</h3>
          <div className="input-container">
            <input
              placeholder="email*"
              className="inputs"
              name="email"
              type="text"
              onChange={handleChange}
              value={email}
            ></input>
            {errors.email && <p className="error-paragraph">{errors.email}</p>}
          </div>
        </div>

        <div className="second-input">
          <h3>Subject*</h3>
          <div className="input-container">
            <input
              placeholder="subject*"
              className="inputs"
              name="subject"
              type="text"
              onChange={handleChange}
              value={subject}
            ></input>
            {errors.subject && (
              <p className="error-paragraph">{errors.subject}</p>
            )}
          </div>
        </div>
      </div>

      <div className="input-row-message">
        <h3>Message*</h3>

        <div className="input-container">
          <textarea
            rows="15"
            placeholder="your message here*"
            name="message"
            onChange={handleChange}
            value={message}
          ></textarea>
        </div>
        {errors.message && <p className="error-paragraph">{errors.message}</p>}
      </div>
      {showMessage && <p>Message Sent Successfully!</p>}
      {isSubmitting ? (
        <button className="btn-contact" disabled>
          <Spinner />
        </button>
      ) : (
        <button className="btn-contact">Submit</button>
      )}
    </form>
  );
};

export default Form;
