import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./info-section.styles.scss";
const InfoSection = () => {
  return (
    <div className="info-section">
      <h1>Contact Information</h1>
      <div className="info-row">
        <h2>Email:</h2>
        <h3 className="contact-email">Jdgodi96@gmail.com</h3>
      </div>

      <div className="info-row">
        <h2 className="header-link">Links:</h2>
        <div className="links-container">
          <a className="git-info" href="https://github.com/jgodi96">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a className="linked-info" href="https://www.linkedin.com/in/jgodi96">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default InfoSection;
