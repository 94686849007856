import React from 'react'
import './title.styles.scss'
function Title(props) {
    return (
        <div className = "Title">
            <h3>
                {props.Title}
                <h1>{props.span}</h1>
            </h3>
        </div>
    )
}

export default Title
